<template>
	<video-base :id="String(id)" category="サイバー空間の脅威に立ち向かえ！" routerName="FaceUp" />
</template>

<script>
import VideoBase from "@/components/VideoBase";
export default {
	components: {
		VideoBase,
	},
  props: ["id"],
}
</script>